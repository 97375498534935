<template>
  <form
    method="post"
    :action="formAction"
    class="l-grid u-margin-bottom--3"
    data-cy="wildlandtrekking-registration-form"
  >
    <section class="l-grid__cell l-grid__cell--12-col u-margin-bottom--1-5">
      <h3 class="headline--5 u-margin-top--0">Trip information</h3>
      <SelectField
        id="tripTypeSelector"
        v-model="tripTypeModel"
        type="text"
        name="tfa_1065"
        :options="tripsData"
        label="Which trip are you registering for? *"
        :error="tripTypeError"
        :required="true"
      />
      <SelectField
        id="tripStartDateSelector"
        v-model="tripStartDateModel"
        type="text"
        name="tfa_1082"
        :options="tripsStartDates"
        label="What is the start date of your trip? *"
        :error="tripStartDateError"
        :required="true"
      />
      <Popper
        :show-popper="showGroupMembersModelPopper"
        class="popper--small"
        data-cy="small"
        @hide="showGroupMembersModelPopper = false"
      >
        <template slot="reference">
          <TextField
            id="tfa_1416"
            v-model="groupMembersModel"
            class="u-padding-bottom--0"
            aria-label="Popper reference"
            type="text"
            name="tfa_1416"
            label="Names of group members traveling with you"
            :error="groupMembersError"
            @focus="showGroupMembersModelPopper = true"
          />
        </template>
        <template slot="content"
          >List the names of all group members traveling with you.</template
        >
      </Popper>
    </section>

    <section
      class="
        l-grid__cell l-grid__cell--12-col
        u-margin-top--2
        u-margin-bottom--1-5
      "
    >
      <h3 class="headline--5 u-margin-top--0">Contact information</h3>
      <TextField
        id="tfa_4"
        v-model="firstNameModel"
        type="text"
        name="tfa_4"
        label="First name *"
        :error="firstNameError"
        :required="true"
      />
      <TextField
        id="tfa_5"
        v-model="lastNameModel"
        type="text"
        name="tfa_5"
        label="Last name *"
        :error="lastNameError"
        :required="true"
      />
      <SelectField
        id="countryOfResidenceSelector"
        v-model="countryOfResidenceModel"
        type="text"
        name="tfa_70"
        :options="countryOfResidence"
        label="Country of residence *"
        :error="countryOfResidenceError"
        :required="true"
      />
      <Popper
        :show-popper="showMobilePhoneModelPopper"
        class="popper--small"
        data-cy="small"
        @hide="showMobilePhoneModelPopper = false"
      >
        <template slot="reference">
          <TextField
            id="tfa_8"
            v-model="mobilePhoneModel"
            type="text"
            name="tfa_8"
            label="Mobile phone *"
            :error="mobilePhoneError"
            :required="true"
            @focus="showMobilePhoneModelPopper = true"
          />
        </template>
        <template slot="content"
          >For contact while you are traveling. Alternatively you could put down
          a number where we can reach you while traveling.</template
        >
      </Popper>
      <TextField
        id="tfa_6"
        v-model="emailModel"
        class="u-padding-bottom--0"
        type="text"
        name="tfa_6"
        label="Email *"
        :error="emailError"
        :required="true"
      />
      <TextField
        id="tfa_650"
        v-model="preTripLodgingModel"
        class="u-display--none"
        type="text"
        name="tfa_650"
        helper-text="Where are you staying the night before your trip begins?"
        label="Pre-trip Lodging"
        :error="preTripLodgingError"
      />
    </section>

    <section
      class="
        l-grid__cell l-grid__cell--12-col
        u-margin-top--2
        u-margin-bottom--1-5
      "
    >
      <h3 class="headline--5 u-margin-top--0">
        Medical and Personal Information
      </h3>
      <DatePicker
        id="tfa_313"
        v-model="dateOfBirthModel"
        name="tfa_313"
        label="Date of birth *"
        button-label="Toggle calendar"
        :error="dateOfBirthError"
        :required="true"
      />
      <SelectField
        id="heightWeightSystemSelector"
        v-model="heightWeightSystemModel"
        type="text"
        name="tfa_672"
        :options="heightWeightSystem"
        label="Preferred system for height and weight *"
        :error="heightWeightSystemError"
        :required="true"
      />

      <template v-if="heightWeightSystemModel === 'tfa_673'">
        <SelectField
          id="heightFeetSelector"
          v-model="heightFeetModel"
          type="text"
          name="tfa_675"
          :options="heightsInFeets"
          label="Height (feet) *"
          :error="heightFeetError"
          :required="true"
        />
        <SelectField
          id="heightInchesSelector"
          v-model="heightInchesModel"
          type="text"
          name="tfa_681"
          :options="heightsInInches"
          label="Height (inches) *"
          :error="heightInchesError"
          :required="true"
        />
        <SelectField
          id="weightInPoundsSelector"
          v-model="weightPoundsModel"
          type="text"
          name="tfa_694"
          :options="weightsInPounds"
          label="Weight (pounds) *"
          :error="weightPoundsError"
          :required="true"
        />
      </template>

      <template v-else-if="heightWeightSystemModel === 'tfa_674'">
        <SelectField
          id="heightInCentimetersSelector"
          v-model="heightCentimetersModel"
          type="text"
          name="tfa_763"
          :options="heightsInCentimeters"
          label="Height (Centimeters) *"
          :error="heightCentimetersError"
          :required="true"
        />
        <SelectField
          id="weightInKilogramsSelector"
          v-model="weightKilogramsModel"
          type="text"
          name="tfa_911"
          :options="weightsInKilograms"
          label="Weight (Kilograms) *"
          :error="weightKilogramsError"
          :required="true"
        />
      </template>
      <p>
        We use height and weight details to fit equipment such as backpacks and
        sleeping bags. We respect your privacy and your information will only be
        shared with our booking team and your guide who will be preparing your
        gear.
      </p>
      <SelectField
        id="dietaryRequirementsSelector"
        v-model="dietaryRequirementsModel"
        class="u-padding-bottom--0"
        type="text"
        name="tfa_624"
        :options="[
          { id: '', value: '' },
          { id: 'tfa_625', value: 'Yes' },
          { id: 'tfa_626', value: 'No' },
        ]"
        label="Dietary requirements *"
        :error="dietaryRequirementsError"
        :required="true"
      />
      <div>
        <p
          class="field__help"
          :class="{ error__margin: dietaryRequirementsError.length }"
        >
          Do you have any special dietary restrictions or requirements? We can
          only cater for special dietary restrictions or requirements if you
          tell us in advance.
        </p>
      </div>

      <template v-if="dietaryRequirementsModel === 'tfa_625'">
        <Popper
          :show-popper="showDietarySummarizeModelPopper"
          class="popper--small"
          data-cy="small"
          @hide="showDietarySummarizeModelPopper = false"
        >
          <template slot="reference">
            <TextField
              id="tfa_327"
              v-model="dietarySummarizeModel"
              data-cy="dietary-summarize-field"
              type="text"
              name="tfa_327"
              label="Summarize dietary requirements *"
              :error="dietarySummarizeError"
              :required="true"
              @focus="showDietarySummarizeModelPopper = true"
            />
          </template>
          <template slot="content"
            >If yes, please summarize your dietary requirements here.</template
          >
        </Popper>
      </template>

      <p class="u-margin-top--3 sm:u-margin-top--1">Physical activity key:</p>
      <ul class="u-margin-bottom--0">
        <li>
          <b>Extremely active:</b>
          Vigorous exercise (significant heart rate increase/very difficult to
          talk in complete sentences) at least 5-6 days/week. Long runs, bike
          rides, swims, HIIT, crossfit, or other athletic activity.
        </li>
        <li>
          <b>Very active:</b>
          Work out regularly (significant heart rate increase/can talk in short
          bursts of words) at least 3-4 days/week. Long runs, bike rides, swims,
          gym workouts, yoga, or other athletic activity.
        </li>
        <li>
          <b>Active:</b>
          Moderate physical activity (increased heart rate/able to talk in full
          sentences) 1-2 days/week. Walks, runs, bike rides, swims, hikes, yoga,
          gym workouts, or other athletic activity.
        </li>
        <li>
          <b>Somewhat active:</b>
          Easy to moderate physical activity (slightly increased heart rate/can
          readily talk or sing) 3 times per month, or less. Walks, runs, swims,
          hikes, gym workouts, or other athletic activity.
        </li>
        <li>
          <b>Not active:</b>
          Very limited physical activity.
        </li>
      </ul>

      <SelectField
        id="physicalActivitySelector"
        v-model="physicalActivityModel"
        class="u-padding-bottom--0"
        type="text"
        name="tfa_1026"
        :options="physicalActivities"
        label="Physical activity *"
        :error="physicalActivityError"
        :required="true"
        @input="
          level3BPTripsModelFn();
          physicalActivitySelectionModelFn();
        "
      />

      <p
        class="field__help"
        :class="{ error__margin: physicalActivityError.length }"
      >
        Using the key above please select your level of physical activity.
      </p>

      <SelectField
        id="pastHikingExperienceSelector"
        v-model="pastHikingExperienceModel"
        type="text"
        name="tfa_1032"
        :options="pastHikingExperiences"
        label="Past hiking & backpacking experience *"
        :error="pastHikingExperienceError"
        :required="true"
        @input="level3BPTripsModelFn()"
      />

      <template v-if="pastHikingExperienceModel">
        <Popper
          :show-popper="showPastHikingExperienceModelPopper"
          class="popper--small"
          data-cy="small"
          @hide="showPastHikingExperienceModelPopper = false"
        >
          <template slot="reference">
            <TextField
              id="tfa_329"
              v-model="elaboratePastHikingExperienceModel"
              data-cy="elaborate-past-hiking-experience-field"
              type="text"
              name="tfa_329"
              label="Physical activity & hiking experience"
              :error="elaboratePastHikingExperienceError"
              @focus="showPastHikingExperienceModelPopper = true"
            />
          </template>
          <template slot="content"
            >If you would like, elaborate on your physical activity and hiking
            experience. It will help us provide you with the best possible
            experience.</template
          >
        </Popper>
      </template>

      <TextField
        id="tfa_1041"
        v-model="level3BPTripsModel"
        class="u-display--none"
        type="text"
        name="tfa_1041"
        label="Flag for level 3 BP trips"
        :error="level3BPTripsError"
      />
      <SelectField
        id="medicalHistorySelector"
        v-model="medicalHistoryModel"
        class="u-padding-bottom--0"
        type="text"
        name="tfa_621"
        :options="[
          { id: '', value: '' },
          { id: 'tfa_622', value: 'Yes' },
          { id: 'tfa_623', value: 'No' },
        ]"
        label="Medical history"
        :error="medicalHistoryError"
      />
      <p class="field__help u-margin-bottom--0">
        Have you ever had any serious injuries, illnesses, operations, or
        hospitalizations? Has there been any change in your general health in
        the past year?
      </p>

      <template v-if="medicalHistoryModel === 'tfa_622'">
        <Popper
          :show-popper="showSummarizeMedicalHistoryModelPopper"
          class="popper--small"
          data-cy="small"
          @hide="showSummarizeMedicalHistoryModelPopper = false"
        >
          <template slot="reference">
            <TextField
              id="tfa_314"
              v-model="summarizeMedicalHistoryModel"
              data-cy="summarize-medical-history-field"
              type="text"
              name="tfa_314"
              label="Summarize recent medical history *"
              :error="summarizeMedicalHistoryError"
              :required="true"
              @focus="showSummarizeMedicalHistoryModelPopper = true"
            />
          </template>
          <template slot="content"
            >Please explain recent medical history.</template
          >
        </Popper>
      </template>

      <SelectField
        id="physicalLimitationsSelector"
        v-model="physicalLimitationsModel"
        class="u-padding-bottom--0 u-display--none"
        type="text"
        name="tfa_627"
        :options="[
          { id: '', value: '' },
          { id: 'tfa_628', value: 'Yes' },
          { id: 'tfa_629', value: 'No' },
        ]"
        label="Physical limitations"
        :error="physicalLimitationsError"
      />
      <p class="u-display--none">
        <span class="field__help">
          Do you have any physical limitations or impairments? Do you have any
          balance issues and/or a fear of heights?
        </span>
      </p>

      <p class="u-margin-top--2">
        Do you experience any of the following while hiking or during exercise?
        Check all that apply. *
      </p>
      <!-- @vue-ignore -->
      <Checkbox
        id="tfa_1059"
        v-model="tfa1059Model"
        name="tfa_1059"
        value="tfa_1059"
        data-cy="default"
        class="u-margin-top--0"
        >Issues with your feet, ankles, knees, or hips that would prevent hiking
        for an extended period of time.</Checkbox
      >
      <!-- @vue-ignore -->
      <Checkbox
        id="tfa_1062"
        v-model="tfa1062Model"
        name="tfa_1062"
        value="tfa_1062"
        data-cy="default"
        class="u-margin-top--0"
        >Issues with balance, footing, or vertigo.</Checkbox
      >
      <!-- @vue-ignore -->
      <Checkbox
        id="tfa_1060"
        v-model="tfa1060Model"
        name="tfa_1060"
        value="tfa_1060"
        data-cy="default"
        class="u-margin-top--0"
        >Difficulty with exposure to heights.</Checkbox
      >
      <!-- @vue-ignore -->
      <Checkbox
        id="tfa_1061"
        v-model="tfa1061Model"
        name="tfa_1061"
        value="tfa_1061"
        data-cy="default"
        class="u-margin-top--0"
        >Adverse reactions to hot temperatures.</Checkbox
      >
      <!-- @vue-ignore -->
      <Checkbox
        id="tfa_1063"
        v-model="tfa1063Model"
        name="tfa_1063"
        value="tfa_1063"
        data-cy="default"
        class="u-margin-top--0 u-margin-bottom--0"
        >None of the above.</Checkbox
      >
    </section>

    <section
      class="
        l-grid__cell l-grid__cell--12-col
        u-margin-top--2
        u-margin-bottom--1-5
      "
    >
      <h3 class="headline--5 u-margin-top--0">Emergency Contact Person</h3>
      <Popper
        :show-popper="showEmergencyContactModelPopper"
        class="popper--small"
        data-cy="small"
        @hide="showEmergencyContactModelPopper = false"
      >
        <template slot="reference">
          <TextField
            id="tfa_333"
            v-model="emergencyContactModel"
            type="text"
            name="tfa_333"
            label="Emergency contact *"
            :error="emergencyContactError"
            :required="true"
            @focus="showEmergencyContactModelPopper = true"
          />
        </template>
        <template slot="content"
          >Name and phone number of an emergency contact NOT joining you on this
          trip.</template
        >
      </Popper>
      <Popper
        :show-popper="showEmergencyContactPhoneModelPopper"
        class="popper--small"
        data-cy="small"
        @hide="showEmergencyContactPhoneModelPopper = false"
      >
        <template slot="reference">
          <TextField
            id="tfa_334"
            v-model="emergencyContactPhoneModel"
            class="u-padding-bottom--0"
            type="text"
            name="tfa_334"
            label="Emergency contact phone *"
            :error="emergencyContactPhoneError"
            :required="true"
            @focus="showEmergencyContactPhoneModelPopper = true"
          />
        </template>
        <template slot="content"
          >Name and phone number of an emergency contact NOT joining you on this
          trip.</template
        >
      </Popper>
    </section>

    <section
      class="
        l-grid__cell l-grid__cell--12-col
        u-margin-top--2
        u-margin-bottom--1-5
      "
    >
      <h3 class="headline--5 u-margin-top--0">
        Do you have or have you ever had any of the following?
      </h3>
      <SelectField
        id="severAllergiesSelector"
        v-model="severeAllergiesModel"
        class="u-padding-bottom--0"
        type="text"
        name="tfa_339"
        :options="[
          { id: '', value: '' },
          { id: 'tfa_343', value: 'Yes' },
          { id: 'tfa_344', value: 'No' },
        ]"
        label="Severe allergies *"
        :error="severeAllergiesError"
        :required="true"
      />
      <p
        class="field__help"
        :class="{ error__margin: severeAllergiesError.length }"
      >
        Do you have any severe or life-threatening allergies?
      </p>

      <template v-if="severeAllergiesModel === 'tfa_343'">
        <Popper
          :show-popper="showExplainAllergiesModelPopper"
          class="popper--small"
          data-cy="small"
          @hide="showExplainAllergiesModelPopper = false"
        >
          <template slot="reference">
            <TextField
              id="tfa_345"
              v-model="explainAllergiesModel"
              data-cy="explain-allergies-field"
              type="text"
              name="tfa_345"
              label="Please explain allergies *"
              :error="explainAllergiesError"
              :required="true"
              @focus="showExplainAllergiesModelPopper = true"
            />
          </template>
          <template slot="content"
            >If yes, please explain your severe allergy. And do you carry an
            epi-pen?</template
          >
        </Popper>
      </template>

      <SelectField
        id="highBloodPressureSelector"
        v-model="highBloodPressureModel"
        type="text"
        name="tfa_361"
        :options="[
          { id: '', value: '' },
          { id: 'tfa_362', value: 'Yes' },
          { id: 'tfa_363', value: 'No' },
        ]"
        label="High blood pressure, low blood pressure, or low heart rate *"
        :error="highBloodPressureError"
        :required="true"
      />
      <Popper
        :show-popper="showAsthmaModelPopper"
        class="popper--small"
        data-cy="small"
        @hide="showAsthmaModelPopper = false"
      >
        <template slot="reference">
          <div @click="showAsthmaModelPopper = true">
            <SelectField
              id="asthmaSelector"
              v-model="asthmaModel"
              type="text"
              name="tfa_379"
              :options="[
                { id: '', value: '' },
                { id: 'tfa_380', value: 'Yes' },
                { id: 'tfa_381', value: 'No' },
              ]"
              label="Asthma *"
              :error="asthmaError"
              :required="true"
              @click="showAsthmaModelPopper = true"
            />
          </div>
        </template>
        <template slot="content"
          >If yes, please use box below to let us know if your asthma is
          exercise induced, if you'll bring your medication on your trip, and if
          you've ever been hospitalized.</template
        >
      </Popper>
      <Popper
        :show-popper="showCardiovascularDiseasesModelPopper"
        class="popper--small"
        data-cy="small"
        @hide="showCardiovascularDiseasesModelPopper = false"
      >
        <template slot="reference">
          <div @click="showCardiovascularDiseasesModelPopper = true">
            <SelectField
              id="cardiovascularDiseasesSelector"
              v-model="cardiovascularDiseasesModel"
              type="text"
              name="tfa_391"
              :options="[
                { id: '', value: '' },
                { id: 'tfa_392', value: 'Yes' },
                { id: 'tfa_393', value: 'No' },
              ]"
              label="Cardiovascular disease, heart attack, or stroke *"
              :error="cardiovascularDiseasesError"
              :required="true"
            />
          </div>
        </template>
        <template slot="content">(arteriosclerosis, angina, COPD)</template>
      </Popper>
      <SelectField
        id="diabetesSelector"
        v-model="diabetesModel"
        type="text"
        name="tfa_400"
        :options="[
          { id: '', value: '' },
          { id: 'tfa_401', value: 'Yes' },
          { id: 'tfa_402', value: 'No' },
        ]"
        label="Diabetes *"
        :error="diabetesError"
        :required="true"
      />

      <template
        v-if="
          highBloodPressureModel === 'tfa_362' ||
          asthmaModel === 'tfa_380' ||
          cardiovascularDiseasesModel === 'tfa_392' ||
          diabetesModel === 'tfa_401'
        "
      >
        <Popper
          :show-popper="showExplainYesAnswersModelPopper"
          class="popper--small"
          data-cy="small"
          @hide="showExplainYesAnswersModelPopper = false"
        >
          <template slot="reference">
            <TextField
              id="tfa_616"
              v-model="explainYesAnswersModel"
              data-cy="explain-yes-answer-field"
              type="text"
              name="tfa_616"
              label="Please provide details about 'Yes' answers *"
              :error="explainYesAnswersError"
              :required="true"
              @focus="showExplainYesAnswersModelPopper = true"
            />
          </template>
          <template slot="content"
            >If you answered 'Yes' to any of the above diseases or conditions
            please use this box to elaborate. If you have asthma please let us
            know if your asthma is exercise induced, if you'll bring your
            medication on your trip, and if you've ever been
            hospitalized.</template
          >
        </Popper>
      </template>

      <SelectField
        id="medicationsSelector"
        v-model="medicationsModel"
        class="u-padding-bottom--0"
        type="text"
        name="tfa_421"
        :options="[
          { id: '', value: '' },
          { id: 'tfa_422', value: 'Yes' },
          { id: 'tfa_423', value: 'No' },
        ]"
        label="Medications or prescriptions *"
        :error="medicationsError"
        :required="true"
      />
      <p
        class="u-margin-bottom--0 field__help"
        :class="{ error__margin: medicationsError.length }"
      >
        Are you taking any medications, over the counter or prescription?
      </p>

      <template v-if="medicationsModel === 'tfa_422'">
        <Popper
          :show-popper="showListOfMedicationsModelPopper"
          class="popper--small"
          data-cy="small"
          @hide="showListOfMedicationsModelPopper = false"
        >
          <template slot="reference">
            <TextField
              id="tfa_426"
              v-model="listOfMedicationsModel"
              data-cy="list-of-medications-field"
              type="text"
              name="tfa_426"
              label="List of medications, and reasons for taking *"
              :error="listOfMedicationsError"
              :required="true"
              @focus="showListOfMedicationsModelPopper = true"
            />
          </template>
          <template slot="content"
            >If yes, please list the drug name(s) and indicate the reason for
            taking each drug.</template
          >
        </Popper>
      </template>
    </section>

    <section class="l-grid__cell l-grid__cell--12-col u-margin-top--2">
      <h3 class="headline--5 u-margin-top--0">Gear Checklist</h3>
      <SelectField
        id="bringOwnSleepingBagSelector"
        v-model="bringOwnSleepingBagModel"
        class="u-padding-bottom--0"
        type="text"
        name="tfa_1042"
        :options="[
          { id: '', value: '' },
          { id: 'tfa_1043', value: 'Yes' },
          { id: 'tfa_1044', value: 'No' },
        ]"
        label="Will you bring your own sleeping bag?"
        :error="bringOwnSleepingBagError"
      />
      <p class="field__help u-margin-bottom--0">
        We recommend bringing your own, however we're also happy to provide you
        with a bag at no extra charge.
        <a
          href="https://wildlandtrekking.com/bringing-your-own-sleeping-bag/"
          target="_blank"
          >Click</a
        >
        here to read more.
      </p>

      <template v-if="bringOwnSleepingBagModel === 'tfa_1043'">
        <Popper
          :show-popper="showTemperatureRatingModelPopper"
          class="popper--small"
          data-cy="small"
          @hide="showTemperatureRatingModelPopper = false"
        >
          <template slot="reference">
            <TextField
              id="tfa_1417"
              v-model="temperatureRatingModel"
              type="text"
              name="tfa_1417"
              label="Temperature rating *"
              :error="temperatureRatingError"
              :required="true"
              @focus="showTemperatureRatingModelPopper = true"
            />
          </template>
          <template slot="content"
            >Please enter the temperature rating of the sleeping bag you are
            bringing.</template
          >
        </Popper>
      </template>

      <p class="u-margin-top--2">
        Check any of the following gear you plan on bringing. This gear is
        supplied by us but you're welcome to bring your own if it's both
        appropriate and your preference.
      </p>
      <Checkbox
        id="tfa_447"
        v-model="bringingTentModel"
        class="u-margin-top--0"
        name="tfa_447"
        data-cy="default"
      >
        Tent</Checkbox
      >
      <Checkbox
        id="tfa_492"
        v-model="bringingSleepingPadModel"
        class="u-margin-top--0"
        name="tfa_492"
        data-cy="default"
      >
        Sleeping Pad</Checkbox
      >
      <Checkbox
        id="tfa_493"
        v-model="bringingBackpackModel"
        class="u-margin-top--0"
        name="tfa_493"
        data-cy="default"
      >
        Backpack</Checkbox
      >
      <Checkbox
        id="tfa_494"
        v-model="bringingTrekkingPolesModel"
        class="u-margin-top--0 u-margin-bottom--0-5"
        name="tfa_494"
        data-cy="default"
      >
        Trekking Poles</Checkbox
      >

      <template v-if="bringingBackpackModel">
        <Popper
          :show-popper="showBackpackSizeModelPopper"
          class="popper--small"
          data-cy="small"
          @hide="showBackpackSizeModelPopper = false"
        >
          <template slot="reference">
            <TextField
              id="tfa_1418"
              v-model="backpackSizeModel"
              data-cy="temperature-rating-field"
              type="text"
              name="tfa_1418"
              label="Backpack Size *"
              :error="backpackSizeError"
              :required="true"
              @focus="showBackpackSizeModelPopper = true"
            />
          </template>
          <template slot="content"
            >Enter the volume of the backpack you are bringing. For example, 32
            Liters or 1950 cubic inches.</template
          >
        </Popper>
      </template>

      <TextField
        id="tfa_1025"
        v-model="physicalActivitySelectionModel"
        class="u-display--none"
        type="text"
        name="tfa_1025"
        label="Physical Activity Selection"
        :error="physicalActivitySelectionError"
      />
      <TextField
        id="tfa_1045"
        v-model="regCompleteDateModel"
        class="u-display--none"
        type="text"
        name="tfa_1045"
        label="Registration Complete Date"
        :error="regCompleteDateError"
        @input="newRegCompleteDateModelFn()"
      />
      <TextField
        id="tfa_1046"
        v-model="newRegCompleteDateModel"
        class="u-display--none"
        type="text"
        name="tfa_1046"
        label="New Reg Complete Date"
        :error="newRegCompleteDateError"
      />

      <input type="hidden" value="4600469" name="tfa_dbFormId" />
      <input type="hidden" value="" name="tfa_dbResponseId" />
      <input
        type="hidden"
        value="e79ca9cb93ec8b6f18ad49eaadcf31db"
        name="tfa_dbControl"
      />
      <input type="hidden" value="" name="tfa_dbWorkflowSessionUuid" />
      <input type="hidden" value="54" name="tfa_dbVersionId" />
      <input type="hidden" value="" name="tfa_switchedoff" />

      <Button
        class="button--primary button--large u-margin-top--2"
        data-cy="large"
        type="submit"
        :on-click="handleValidationScroll"
        >Send my registration</Button
      >
      <div class="u-margin-top--1">
        <small
          ><b>Note:</b> Once you send the registration you will be redirected to
          Wildland Trekking website</small
        >
      </div>
    </section>
  </form>
</template>

<script lang="ts">
import Checkbox from "atlas/src/components/Checkbox/Checkbox.vue";
import SelectField from "atlas/src/components/SelectField/SelectField.vue";
import TextField from "atlas/src/components/TextField/TextField.vue";
import DatePicker from "atlas/src/components/DatePicker/components/Datepicker.vue";
import Button from "atlas/src/components/Button/Button.vue";
import Popper from "atlas/src/components/Popper/Popper.vue";
import SimpleVueValidation from "simple-vue-validator";
import dayjs from "dayjs";
import tripsJson from "./trips.json";
import { iosDetector } from "~~/lib/utils/iosDetector";

const Validator = SimpleVueValidation.Validator;

type SelectFieldOptions = { id: string; value: string | number }[];

export default {
  name: "WildlandtrekkingRegistrationForm",
  components: {
    Checkbox,
    SelectField,
    TextField,
    DatePicker,
    Button,
    Popper,
  },
  mixins: [SimpleVueValidation.mixin],
  data() {
    return {
      formAction:
        "https://wildlandtrekking.tfaforms.net/api_v2/workflow/processor",
      showGroupMembersModelPopper: false,
      showMobilePhoneModelPopper: false,
      showDietarySummarizeModelPopper: false,
      showPastHikingExperienceModelPopper: false,
      showSummarizeMedicalHistoryModelPopper: false,
      showEmergencyContactModelPopper: false,
      showEmergencyContactPhoneModelPopper: false,
      showExplainAllergiesModelPopper: false,
      showAsthmaModelPopper: false,
      showCardiovascularDiseasesModelPopper: false,
      showExplainYesAnswersModelPopper: false,
      showTemperatureRatingModelPopper: false,
      showBackpackSizeModelPopper: false,
      showListOfMedicationsModelPopper: false,
      tripTypeModel: "",
      tripStartDateModel: "",
      groupMembersModel: "",
      firstNameModel: "",
      lastNameModel: "",
      countryOfResidenceModel: "",
      mobilePhoneModel: "",
      emailModel: "",
      preTripLodgingModel: "",
      dateOfBirthModel: "",
      heightWeightSystemModel: "",
      heightFeetModel: "",
      heightInchesModel: "",
      weightPoundsModel: "",
      heightCentimetersModel: "",
      weightKilogramsModel: "",
      dietaryRequirementsModel: "",
      dietarySummarizeModel: "",
      physicalActivityModel: "",
      pastHikingExperienceModel: "",
      elaboratePastHikingExperienceModel: "",
      level3BPTripsModel: "",
      medicalHistoryModel: "",
      summarizeMedicalHistoryModel: "",
      physicalLimitationsModel: "",
      tfa1059Model: "",
      tfa1062Model: "",
      tfa1060Model: "",
      tfa1061Model: "",
      tfa1063Model: "",
      emergencyContactModel: "",
      emergencyContactPhoneModel: "",
      severeAllergiesModel: "",
      explainAllergiesModel: "",
      highBloodPressureModel: "",
      asthmaModel: "",
      cardiovascularDiseasesModel: "",
      diabetesModel: "",
      explainYesAnswersModel: "",
      medicationsModel: "",
      listOfMedicationsModel: "",
      bringOwnSleepingBagModel: "",
      temperatureRatingModel: "",
      bringingTentModel: "",
      bringingSleepingPadModel: "",
      bringingBackpackModel: "",
      bringingTrekkingPolesModel: "",
      backpackSizeModel: "",
      physicalActivitySelectionModel: "",
      regCompleteDateModel: "",
      newRegCompleteDateModel: "",
      tripTypeError: [],
      tripStartDateError: [],
      groupMembersError: [],
      firstNameError: [],
      lastNameError: [],
      countryOfResidenceError: [],
      mobilePhoneError: [],
      emailError: [],
      preTripLodgingError: [],
      dateOfBirthError: [],
      heightWeightSystemError: [],
      heightFeetError: [],
      heightInchesError: [],
      weightPoundsError: [],
      heightCentimetersError: [],
      weightKilogramsError: [],
      dietaryRequirementsError: [],
      dietarySummarizeError: [],
      physicalActivityError: [],
      pastHikingExperienceError: [],
      elaboratePastHikingExperienceError: [],
      level3BPTripsError: [],
      medicalHistoryError: [],
      summarizeMedicalHistoryError: [],
      physicalLimitationsError: [],
      emergencyContactError: [],
      emergencyContactPhoneError: [],
      severeAllergiesError: [],
      explainAllergiesError: [],
      highBloodPressureError: [],
      asthmaError: [],
      cardiovascularDiseasesError: [],
      diabetesError: [],
      explainYesAnswersError: [],
      medicationsError: [],
      listOfMedicationsError: [],
      bringOwnSleepingBagError: [],
      temperatureRatingError: [],
      backpackSizeError: [],
      physicalActivitySelectionError: [],
      regCompleteDateError: [],
      newRegCompleteDateError: [],
    };
  },
  computed: {
    tripsData(): SelectFieldOptions {
      const tripOptions = [{ id: "", value: "" }];
      tripsJson.trips.forEach((trip) =>
        tripOptions.push({ id: trip.id, value: trip.name })
      );
      return tripOptions;
    },
    tripsStartDates(): SelectFieldOptions {
      const trip = tripsJson.trips.find(
        (trip) => trip.id === this.tripTypeModel
      );
      const dates = [{ id: "", value: "" }];

      trip?.start_dates.forEach((date) => {
        dates.push({ id: date.id, value: date.value });
      });
      return dates;
    },
    countryOfResidence(): SelectFieldOptions {
      return tripsJson.countryOfResidence;
    },
    heightWeightSystem(): SelectFieldOptions {
      return tripsJson.heightWeightSystem;
    },
    heightsInFeets(): SelectFieldOptions {
      return tripsJson.heightsInFeets;
    },
    heightsInInches(): SelectFieldOptions {
      return tripsJson.heightsInInches;
    },
    weightsInPounds(): SelectFieldOptions {
      return tripsJson.weightsInPounds;
    },
    heightsInCentimeters(): SelectFieldOptions {
      return tripsJson.heightsInCentimeters;
    },
    weightsInKilograms(): SelectFieldOptions {
      return tripsJson.weightsInKilograms;
    },
    physicalActivities(): SelectFieldOptions {
      return tripsJson.physicalActivities;
    },
    pastHikingExperiences(): SelectFieldOptions {
      return tripsJson.pastHikingExperiences;
    },
  },
  created() {
    this.newRegCompleteDateModelFn();
  },
  validators: {
    tripTypeModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.tripTypeError = validation._messages;
      return validation;
    },
    tripStartDateModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.tripStartDateError = validation._messages;
      return validation;
    },
    firstNameModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.firstNameError = validation._messages;
      return validation;
    },
    lastNameModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.lastNameError = validation._messages;
      return validation;
    },
    countryOfResidenceModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.countryOfResidenceError = validation._messages;
      return validation;
    },
    mobilePhoneModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.mobilePhoneError = validation._messages;
      return validation;
    },
    emailModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required().email();
      this.emailError = validation._messages;
      return validation;
    },
    dateOfBirthModel(value: string | number) {
      value = dayjs(value).isValid() ? dayjs(value).unix() : "";
      const maxdate = dayjs().subtract(3, "year").unix();
      const mindate = dayjs().subtract(100, "year").unix();
      // @ts-ignore
      const validation = Validator.value(value)
        .required()
        .between(
          mindate,
          maxdate,
          `This date must be between ${dayjs
            .unix(mindate)
            .format("L")} - ${dayjs.unix(maxdate).format("L")}.`
        );
      this.dateOfBirthError = validation._messages;
      return validation;
    },
    heightWeightSystemModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.heightWeightSystemError = validation._messages;
      return validation;
    },
    heightFeetModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.heightFeetError = validation._messages;
      return validation;
    },
    heightInchesModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.heightInchesError = validation._messages;
      return validation;
    },
    weightPoundsModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.weightPoundsError = validation._messages;
      return validation;
    },
    heightCentimetersModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.heightCentimetersError = validation._messages;
      return validation;
    },
    weightKilogramsModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.weightKilogramsError = validation._messages;
      return validation;
    },
    dietaryRequirementsModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.dietaryRequirementsError = validation._messages;
      return validation;
    },
    dietarySummarizeModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.dietarySummarizeError = validation._messages;
      return validation;
    },
    physicalActivityModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.physicalActivityError = validation._messages;
      return validation;
    },
    pastHikingExperienceModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.pastHikingExperienceError = validation._messages;
      return validation;
    },
    summarizeMedicalHistoryModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.summarizeMedicalHistoryError = validation._messages;
      return validation;
    },
    emergencyContactModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.emergencyContactError = validation._messages;
      return validation;
    },
    emergencyContactPhoneModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.emergencyContactPhoneError = validation._messages;
      return validation;
    },
    severeAllergiesModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.severeAllergiesError = validation._messages;
      return validation;
    },
    explainAllergiesModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.explainAllergiesError = validation._messages;
      return validation;
    },
    highBloodPressureModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.highBloodPressureError = validation._messages;
      return validation;
    },
    asthmaModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.asthmaError = validation._messages;
      return validation;
    },
    cardiovascularDiseasesModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.cardiovascularDiseasesError = validation._messages;
      return validation;
    },
    diabetesModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.diabetesError = validation._messages;
      return validation;
    },
    explainYesAnswersModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.explainYesAnswersError = validation._messages;
      return validation;
    },
    medicationsModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.medicationsError = validation._messages;
      return validation;
    },
    listOfMedicationsModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.listOfMedicationsError = validation._messages;
      return validation;
    },
    temperatureRatingModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.temperatureRatingError = validation._messages;
      return validation;
    },
    backpackSizeModel(value: string) {
      // @ts-ignore
      const validation = Validator.value(value).required();
      this.backpackSizeError = validation._messages;
      return validation;
    },
  },
  methods: {
    handleValidationScroll(): void {
      // workaround for iOS. iOS has a long standing bug where the browser doesn't scroll up to
      // the invalid required field on submit. So manually implementing the scroll to invalid input field
      if (iosDetector()) {
        const form = document.querySelector("form");
        const invalidInput = form?.querySelectorAll(
          ":scope select:invalid,input:invalid"
        );
        if (invalidInput?.length) {
          invalidInput[0].scrollIntoView();
        }
      }
    },
    level3BPTripsModelFn(): void {
      this.level3BPTripsModel = "0";
      if (
        ["tfa_1034", "tfa_1035", "tfa_1036", "tfa_1040"].includes(
          this.pastHikingExperienceModel
        ) ||
        ["tfa_1029", "tfa_1030", "tfa_1031"].includes(
          this.physicalActivityModel
        )
      ) {
        this.level3BPTripsModel = "1";
      }
    },
    physicalActivitySelectionModelFn(): void {
      switch (this.physicalActivityModel) {
        case "tfa_1027":
          this.physicalActivitySelectionModel =
            "Extremely Active: Vigorous exercise (significant heart rate increase/very difficult to talk in complete sentences) at least 5-6 days/week. Long runs, bike rides, swims, HIIT, crossfit, or other athletic activity.";
          break;
        case "tfa_1028":
          this.physicalActivitySelectionModel =
            "Very Active: Work out regularly (significant heart rate increase/can talk in short bursts of words) at least 3-4 days/week. Long runs, bike rides, swims, gym workouts, yoga, or other athletic activity.";
          break;
        case "tfa_1029":
          this.physicalActivitySelectionModel =
            "Active: Moderate physical activity (increased heart rate/able to talk in full sentences) 1-2 days/week. Walks, runs, bike rides, swims, hikes, yoga, gym workouts, or other athletic activity.";
          break;
        case "tfa_1030":
          this.physicalActivitySelectionModel =
            "Somewhat Active: Easy to moderate physical activity (slightly increased heart rate/can readily talk or sing) 3 times per month, or less. Walks, runs, swims, hikes, gym workouts, other other athletic activity.";
          break;
        case "tfa_1031":
          this.physicalActivitySelectionModel =
            "Not Active: Very limited physical activity.";
          break;
        default:
          this.physicalActivitySelectionModel = "";
          break;
      }
    },
    newRegCompleteDateModelFn(): void {
      if (this.regCompleteDateModel !== "") {
        this.newRegCompleteDateModel = this.regCompleteDateModel;
      } else {
        this.newRegCompleteDateModel = dayjs().format("DD/MMM/YYYY");
      }
    },
  },
};
</script>
<style lang="scss">
@import "./wildlandtrekkingRegistration";
</style>
