<template>
  <Page v-bind="page">
    <div class="u-margin-top--2">
      <ErrorBoundary v-if="headline">
        <PageHeadline
          v-bind="headline"
          data-cy="wildlandtrekking-registration-headline"
        />
      </ErrorBoundary>
      <div
        class="
          l-container l-container--small
          u-margin-top--4
          md:u-margin-top--6
        "
      >
        <ErrorBoundary v-if="introduction">
          <Introduction
            class="u-margin-bottom--3 sm:u-margin-bottom--6"
            :tagline="introduction?.tagline"
            :longDescription="introduction?.longDescription"
            data-cy="wildlandtrekking-registration-introduction"
          />
        </ErrorBoundary>
        <client-only>
          <WildlandtrekkingRegistrationForm />
        </client-only>
      </div>
    </div>
  </Page>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import PageHeadline from "../PageHeadline/PageHeadline.vue";
import Introduction from "../Introduction/Introduction.vue";
import WildlandtrekkingRegistrationForm from "./WildlandtrekkingRegistrationForm.vue";
import { WildlandtrekkingRegistrationPageProps } from "./Props";
import ErrorBoundary from "~~/components/ErrorBoundary/ErrorBoundary.vue";
import Page from "~/components/Page/Page.vue";

export default Vue.extend({
  name: "WildlandtrekkingRegistrationPage",
  components: {
    Page,
    PageHeadline,
    Introduction,
    ErrorBoundary,
    WildlandtrekkingRegistrationForm,
  },
  props: {
    page: {
      type: Object as PropType<WildlandtrekkingRegistrationPageProps["page"]>,
      required: true,
    },
    headline: {
      type: Object as PropType<
        WildlandtrekkingRegistrationPageProps["headline"]
      >,
      required: false,
      default: undefined,
    },
    introduction: {
      type: Object as PropType<
        WildlandtrekkingRegistrationPageProps["introduction"]
      >,
      required: false,
      default: undefined,
    },
  },
});
</script>
